import axios, { AxiosRequestConfig } from 'axios';
// config
import {
  HOST_API,
  HOST_NOTIFICATIONS,
  HOST_VIDEO_API,
  HOST_APP,
  HOST_PUBLIC_APP,
  HOST_ANALYTICS,
  HOST_PUBLIC_NOTIFICATIONS,
  HOST_COMMENTS,
  HOST_PUBLIC_COMMENTS,
} from '../config';
import { handleTokenExpired, isValidToken, setSession } from './jwt';
import { ITokens } from '../@types/auth';

// ----------------------------------------------------------------------

const axiosInstanceBase = axios.create({
  baseURL: HOST_API,
});

const axiosInstanceVideo = axios.create({
  baseURL: HOST_VIDEO_API,
});

const axiosInstanceApp = axios.create({
  baseURL: HOST_APP,
});

const axiosInstancePublicApp = axios.create({
  baseURL: HOST_PUBLIC_APP,
});

const axiosInstanceNotification = axios.create({
  baseURL: HOST_NOTIFICATIONS,
});

const axiosInstancePublicNotification = axios.create({
  baseURL: HOST_PUBLIC_NOTIFICATIONS,
});

const axiosInstanceAnalytics = axios.create({
  baseURL: HOST_ANALYTICS,
});

const axiosInstanceComments = axios.create({
  baseURL: HOST_COMMENTS,
});

const axiosInstancePublicComments = axios.create({
  baseURL: HOST_PUBLIC_COMMENTS,
});

export const enum ApiServices {
  BASE,
  VIDEO,
  CONSTANT,
  APP,
  PUBLIC_APP,
  NOTIFICATION,
  PUBLIC_NOTIFICATION,
  COMMENT,
  PUBLIC_COMMENT,
}

const ApiClients = {
  axiosBase: axiosInstanceBase,
  axiosVideo: axiosInstanceVideo,
  axiosPublicApp: axiosInstancePublicApp,
  axiosApp: axiosInstanceApp,
  axiosNotification: axiosInstanceNotification,
  axiosAnalytics: axiosInstanceAnalytics,
  axiosPublicNotification: axiosInstancePublicNotification,
  axiosComment: axiosInstanceComments,
  axiosPublicComment: axiosInstancePublicComments,
};

interface IRequestQueueElem {
  resolve: (value: string | PromiseLike<string>) => void;
  reject: (reason?: any) => void;
}

let isActiveRequest = true;
let requestQueue: IRequestQueueElem[] = [];

const attachTokenToRequest = (request: AxiosRequestConfig<any>, token: string) => {
  if (!request?.headers) return;

  request.headers.Authorization = token;
};

const processQueue = (error: unknown, token: string | null = null) => {
  requestQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      if (token === null) return;

      prom.resolve(token);
    }
  });

  requestQueue = [];
};

Object.values(ApiClients).forEach((instance) =>
  instance.interceptors.request.use(
    async (request) => {
      const tokens = localStorage.getItem('tokens');
      const accessToken = tokens && JSON.parse(tokens).access_token;

      if (
        tokens &&
        !isValidToken(accessToken) &&
        isActiveRequest &&
        request.url !== 'users/refresh'
      ) {
        isActiveRequest = false;
        if (tokens) {
          try {
            const response = await ApiClients.axiosBase.post('users/refresh', {
              refresh_token: JSON.parse(tokens).refresh_token,
            });
            const responseTokens: ITokens = {
              access_token: response.data.access_token,
              refresh_token: response.data.refresh_token,
            };
            setSession(responseTokens);
            if (request.headers) request.headers.Authorization = responseTokens.access_token;
            processQueue(null, responseTokens.access_token);
          } catch (error) {
            handleTokenExpired();
          }
        }
        requestQueue = [];
        isActiveRequest = true;

        return request;
      } else if (!isActiveRequest && request.url !== 'users/refresh') {
        await new Promise<string>(function (resolve, reject) {
          requestQueue.push({ resolve, reject });
        })
          .then((token) => {
            attachTokenToRequest(request, token);
          })
          .catch(() => Promise.reject());

        return request;
      } else {
        if (request.headers) request.headers.Authorization = accessToken;

        return request;
      }
    },
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
  )
);

export default ApiClients;
