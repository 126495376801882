import { createSlice } from '@reduxjs/toolkit';

import { BannerState } from 'src/@types/banner';
import { INITIAL_PER_PAGE } from 'src/config';

const initialState: BannerState = {
  isLoading: false,
  bannerList: {},
  bannerListParams: { per_page: INITIAL_PER_PAGE },
  banner: null,
};

const slice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getBannerList(state, action) {
      state.isLoading = false;
      state.bannerList = action.payload;
    },

    getBanner(state, action) {
      state.isLoading = false;
      state.banner = action.payload;
    },

    setBannerListParams(state, action) {
      state.bannerListParams = action.payload;
    },

    clearBanner(state) {
      state.banner = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getBannerList,
  setBannerListParams,
  getBanner,
  clearBanner,
} = slice.actions;
