import { createSlice } from '@reduxjs/toolkit';

import { NotificationState } from 'src/@types/notification';
import { INITIAL_PER_PAGE } from 'src/config';

const initialState: NotificationState = {
  isLoading: false,
  notifications: {},
  notificationsListParams: {},
  massClientList: {},
  massClientListParams: { per_page: INITIAL_PER_PAGE },
  singleClientList: {},
  singleClientListParams: { per_page: INITIAL_PER_PAGE },
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getNotificationList(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    setNotificationListParams(state, action) {
      state.notificationsListParams = action.payload;
    },

    getMassNotificationClientListSuccess(state, action) {
      state.isLoading = false;
      state.massClientList = action.payload;
    },

    setMassNotificationClientListParams(state, action) {
      state.massClientListParams = action.payload;
    },

    getSingleNotificationClientListSuccess(state, action) {
      state.isLoading = false;
      state.singleClientList = action.payload;
    },

    setSingleNotificationClientListParams(state, action) {
      state.singleClientListParams = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getNotificationList,
  setNotificationListParams,
  getMassNotificationClientListSuccess,
  setMassNotificationClientListParams,
  getSingleNotificationClientListSuccess,
  setSingleNotificationClientListParams,
} = slice.actions;
