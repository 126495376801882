import { dispatch } from '../store';
import ApiClients from '../../utils/axios';
import {
  Course,
  CourseList,
  CourseListParams,
  Faq,
  FaqListParams,
  GoalList,
  ILevel,
} from '../../@types/course';
import {
  startLoading,
  hasError,
  getCourseSuccess,
  getCourseListSuccess,
  getAllCoursesSuccess,
  getGroupListSuccess,
  getGroupSuccess,
  getGoalsListSuccess,
  getLevelsListSuccess,
  getFaqListSuccess,
  getFaqSuccess,
} from '../slices/course';
import { WorkoutQuery } from 'src/@types/workout';

const { axiosVideo } = ApiClients;

export function getCourseListThunk(params: CourseListParams | undefined) {
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: CourseList } = await axiosVideo.get('courses', { params });
      dispatch(getCourseListSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getAllCoursesThunk() {
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: { data: Course[] } } = await axiosVideo.get('courses', {
        params: { per_page: 'all' },
      });
      dispatch(getAllCoursesSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getCourseThunk(id: number) {
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: { data: Course } } = await axiosVideo.get(`courses/${id}`);
      dispatch(getCourseSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function editCourseThunk(id: number, params: WorkoutQuery) {
  return async () => {
    try {
      await axiosVideo.put(`courses/${id}`, params);
      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
    return false;
  };
}

export function createCourseThunk(params: WorkoutQuery) {
  return async () => {
    try {
      const course = await axiosVideo.post(`courses`, params);
      return course;
    } catch (error) {
      dispatch(hasError(error));
    }
    return false;
  };
}

export function deleteCourseThunk(id: number) {
  return async () => {
    dispatch(startLoading());
    try {
      await axiosVideo.delete(`courses/${id}`);
      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getGroupListThunk(params?: any) {
  return async () => {
    try {
      const response: { data: any } = await axiosVideo.get('courses/groups', {
        params,
      });
      dispatch(getGroupListSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getGoalsListThunk() {
  return async () => {
    try {
      const response: { data: { data: GoalList[] } } = await axiosVideo.get('courses/goals');

      dispatch(getGoalsListSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getLevelsListThunk() {
  return async () => {
    try {
      const response: { data: { data: ILevel[] } } = await axiosVideo.get('courses/levels');

      dispatch(getLevelsListSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getGroupThunk(id: number) {
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: { data: any } } = await axiosVideo.get(`courses/groups/${id}`);
      dispatch(getGroupSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createGroupThunk(params: any) {
  return async () => {
    try {
      await axiosVideo.post(`courses/groups`, params);
      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function editGroupThunk(id: number, params: any) {
  return async () => {
    try {
      await axiosVideo.put(`courses/groups/${id}`, params);
      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
    return false;
  };
}

export function deleteGroupThunk(id: number) {
  return async () => {
    dispatch(startLoading());
    try {
      await axiosVideo.delete(`courses/groups/${id}`);
      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getFaqListThunk(params: FaqListParams) {
  return async () => {
    try {
      const response: { data: { data: Faq[] } } = await axiosVideo.get('courses/faqs', { params });

      dispatch(getFaqListSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createFaqThunk(params: any) {
  return async () => {
    try {
      await axiosVideo.post(`courses/faqs`, params);
      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function editFaqThunk(id: number, params: any) {
  return async () => {
    try {
      await axiosVideo.put(`courses/faqs/${id}`, params);
      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
    return false;
  };
}

export function deleteFaqThunk(id: number) {
  return async () => {
    dispatch(startLoading());
    try {
      await axiosVideo.delete(`courses/faqs/${id}`);
      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getFaqThunk(id: number) {
  return async () => {
    try {
      const response: { data: { data: Faq } } = await axiosVideo.get(`courses/faqs/${id}`);

      dispatch(getFaqSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
