import { memo, createRef, useEffect, useState, useCallback } from 'react';
import { Cropper, ReactCropperProps } from 'react-cropper';
import { ICrop, ICropper, ICropperElement } from 'src/@types/crop';

import s from './styles.module.css';

export interface CropperImageProps extends ReactCropperProps {
  cropImg: string | undefined;
  initialCropBoxSize?: ICrop | null;
  onCropGetData: (data: any) => void;
}

const CropperImage = ({
  cropImg,
  onCropGetData,
  initialCropBoxSize,
  ...props
}: CropperImageProps) => {
  const cropperRef = createRef<ICropperElement>();
  const [loadingInterval, setLoadingInterval] = useState<NodeJS.Timeout | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // Ожидаем изображение, потом отрисовку Cropper с этим изображением
  const checkLoaded = useCallback(
    (cropper: ICropper | undefined) => {
      if (!cropper || isLoaded || loadingInterval) return;

      const interval = setInterval(() => {
        if (!cropper.canvasData || !cropper.containerData) return;

        clearInterval(interval);

        setIsLoaded(true);
      }, 100);

      setLoadingInterval(interval);
    },
    [isLoaded, loadingInterval]
  );

  useEffect(() => {
    const cropper = cropperRef.current?.cropper;

    checkLoaded(cropper);

    if (!cropImg || !initialCropBoxSize || !isLoaded || !cropper) return;

    const canvasData = cropper?.canvasData;
    const containerData = cropper?.containerData;
    const containerRatio = canvasData?.naturalHeight / containerData?.height;
    const oldCropBoxData = cropper.getCropBoxData();

    const newWidth = +Math.ceil(initialCropBoxSize.width / containerRatio);
    const newHeight = +Math.ceil(initialCropBoxSize.height / containerRatio);

    // Если новые размеры больше старых, уменьшаем до старых размеров
    // Решает баг, когда кроппер находится на границах изображения
    const newCropBoxData = {
      left: +Math.ceil(initialCropBoxSize.x / containerRatio),
      top: +Math.ceil(initialCropBoxSize.y / containerRatio),
      width: newWidth > oldCropBoxData.width ? oldCropBoxData.width : newWidth,
      height: newHeight > oldCropBoxData.height ? oldCropBoxData.height : newHeight,
    };

    cropper.setCropBoxData(newCropBoxData);
  }, [cropImg, initialCropBoxSize, isLoaded, checkLoaded, cropperRef]);

  // Если интервал не отработал, закрываем его на ComponentWillUnmount
  useEffect(
    () => () => {
      clearInterval(Number(loadingInterval));
    },
    [loadingInterval]
  );

  const handleSave = (detail: ICrop) => {
    if (cropperRef.current?.cropper === undefined) return;

    const { x, y, height, width } = detail;

    const crop = {
      x: +x.toFixed(0),
      y: +y.toFixed(0),
      height: +height.toFixed(0),
      width: +width.toFixed(0),
    };

    onCropGetData(crop ?? { x: 0, y: 0, height: 0, width: 0 });
  };

  return (
    <div className={s.cropperImage}>
      <Cropper
        ref={cropperRef}
        style={{ height: '100%', width: '100%' }}
        autoCrop={true}
        initialAspectRatio={1}
        zoomOnWheel={false}
        preview=".img-preview"
        src={cropImg}
        minContainerWidth={10}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false}
        movable={false}
        guides={true}
        restore={true}
        crop={({ detail: { x, y, height, width } }) => handleSave({ x, y, height, width })}
        {...props}
      />
    </div>
  );
};

export const CropperImageMemo = memo(CropperImage);
