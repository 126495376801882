import { memo, ReactNode } from 'react';
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

interface FormProviderProps {
  children: ReactNode;
  methods: UseFormReturn<any>;
  className?: string;
  onSubmit?: () => void;
}

const FormProvider = ({ children, methods, className, onSubmit }: FormProviderProps) => (
  <Form {...methods}>
    <form onSubmit={onSubmit} className={className}>
      {children}
    </form>
  </Form>
);

export const FormProviderMemo = memo(FormProvider);
