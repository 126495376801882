import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import ApiClients from '../utils/axios';
import { isValidToken, setSession, handleTokenExpired } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType, ITokens } from '../@types/auth';
import { useAbility } from 'src/components/Ability';
import { User } from 'src/@types/user';
import { AxiosResponse } from 'axios';
import { getPermissionsByRole } from 'src/utils/getPermissionsByRole';

// ----------------------------------------------------------------------

const { axiosBase } = ApiClients;

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: undefined;
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: null,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};
const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const ability = useAbility();

  const setAvatarPath = async (user: AuthUser) => {
    if (user!.avatar && user !== null) {
      const response = await axiosBase.get(`files/image/user/avatar/${user.avatar}/40/40`, {
        responseType: 'blob',
      });
      if (response.data) {
        user.avatarPath = URL.createObjectURL(response.data);
      }
      return user;
    } else return user;
  };

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const tokens = localStorage.getItem('tokens');
      if (tokens && isValidToken(JSON.parse(tokens).access_token)) {
        setSession(JSON.parse(tokens));
        const response = await axiosBase.get<AxiosResponse<User>>('users/me');
        const user = response.data.data;
        const rules = getPermissionsByRole(user.roles);
        const newUser = await setAvatarPath(user);
        ability.update(rules);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user: newUser,
          },
        });
      } else if (tokens) {
        const response = await axiosBase.post('users/refresh', {
          refresh_token: JSON.parse(tokens).refresh_token,
        });
        const responseTokens: ITokens = {
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
        };
        setSession(responseTokens);
        const responseUser = await axiosBase.get('users/me');
        const user = responseUser.data.data;
        const rules = getPermissionsByRole(user.roles);
        ability.update(rules);
        const newUser = setAvatarPath(user);

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user: newUser,
          },
        });
      } else {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      handleTokenExpired();
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  const login = async (email: string, password: string) => {
    const response = await axiosBase.post('users/login', {
      email,
      password,
    });

    const responseTokens = {
      access_token: response.data.access_token,
      refresh_token: response.data.refresh_token,
    };

    setSession(responseTokens);
    dispatch({ type: Types.Login });
    await initialize();
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axiosBase.post('account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
