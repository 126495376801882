import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getStoryListThunk, getStoryThunk } from '../thunks/story';
import { Story, StoryList, StoryState } from 'src/@types/story';
import { INITIAL_PER_PAGE } from 'src/config';

const initialState: StoryState = {
  isLoading: false,
  storyList: {},
  storyListParams: { per_page: INITIAL_PER_PAGE },
  story: null,
};

const slice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    setStoryListParams(state, action) {
      state.storyListParams = action.payload;
    },

    clearStory(state) {
      state.story = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoryThunk.fulfilled, (state, { payload }: PayloadAction<Story>) => {
        state.isLoading = false;
        state.story = payload;
      })
      .addCase(getStoryThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStoryThunk.rejected, (state, { payload }) => {
        state.isLoading = false;
        // state.error = payload;
      })
      .addCase(getStoryListThunk.fulfilled, (state, { payload }: PayloadAction<StoryList>) => {
        state.isLoading = false;
        state.storyList = payload;
      })
      .addCase(getStoryListThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStoryListThunk.rejected, (state, { payload }) => {
        state.isLoading = false;
        // state.error = payload;
      });
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, resetError, setStoryListParams, clearStory } = slice.actions;
