import { createSlice } from '@reduxjs/toolkit';
import { UsedeskState } from 'src/@types/usedesk';

const initialState: UsedeskState = {
  isLoading: false,
  usedeskList: null,
  usedeskListParams: {},
  channels: [],
};

const slice = createSlice({
  name: 'usedesk',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getUsedeskList(state, action) {
      state.isLoading = false;
      state.usedeskList = action.payload;
    },

    getChannels(state, action) {
      state.isLoading = false;
      state.channels = action.payload;
    },

    setUseDeskListParams(state, action) {
      state.usedeskListParams = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getUsedeskList,
  setUseDeskListParams,
  getChannels,
} = slice.actions;
