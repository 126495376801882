// components
import type { LabelColor } from './components/Label';
import { SettingsValueProps } from './components/settings/type';

// API
// ----------------------------------------------------------------------

// local
//export const HOST_API = 'http://localhost/kolsa-back-users/public/api/v1/admin/';
//export const HOST_VIDEO_API = 'http://localhost/kolsa-back-video/public/api/v1/';

// test
// export const HOST_API = 'https://back-users.test.kolsa.ru/api/v1/admin/';
// export const HOST_VIDEO_API = 'https://back-video.test.kolsa.ru/api/v1/';
// export const HOST_APP = 'https://back-app.test.kolsa.ru/api/v1/admin/';
// export const HOST_PUBLIC_APP = 'https://back-app.test.kolsa.ru/api/v1/';
// export const HOST_NOTIFICATIONS = 'https://back-notification.test.kolsa.ru/api/v1/admin/';
// export const HOST_PUBLIC_NOTIFICATIONS = 'https://back-notification.test.kolsa.ru/api/v1/';
// export const HOST_ANALYTICS = 'https://back-analytics.test.kolsa.ru/api/v1/';
// export const HOST_COMMENTS = 'https://back-comment.test.kolsa.ru/api/v1/admin';
// export const HOST_PUBLIC_COMMENTS = 'https://back-comment.test.kolsa.ru/api/v1/';

// live
export const HOST_API = 'https://back-users.app.kolsa.ru/api/v1/admin/';
export const HOST_VIDEO_API = 'https://back-video.app.kolsa.ru/api/v1/';
export const HOST_APP = 'https://back-app.app.kolsa.ru/api/v1/admin/';
export const HOST_PUBLIC_APP = 'https://back-app.app.kolsa.ru/api/v1/';
export const HOST_NOTIFICATIONS = 'https://back-notification.app.kolsa.ru/api/v1/admin/';
export const HOST_PUBLIC_NOTIFICATIONS = 'https://back-notification.app.kolsa.ru/api/v1/';
export const HOST_ANALYTICS = 'https://back-analytics.app.kolsa.ru/api/v1/';
export const HOST_COMMENTS = 'https://back-comment.app.kolsa.ru/api/v1/admin';
export const HOST_PUBLIC_COMMENTS = 'https://back-comment.app.kolsa.ru/api/v1/';

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'red',
  themeStretch: true,
};

export const paymentStatuses: Record<number, [string, LabelColor]> = {
  1: ['Paid', 'success'],
  2: ['Not paid', 'default'],
  3: ['Cancelled', 'error'],
  4: ['Cancelled', 'error'],
  5: ['Frozen', 'info'],
};

export const INITIAL_PER_PAGE = 25;
export const PER_PAGE_OPTIONS = [10, 25, 50, 100];
export const PER_PAGE_OPTIONS_ALL = [...PER_PAGE_OPTIONS, { value: -1, label: 'Все' }];

export const clientFreezeCommentRegex = /[^a-zA-Zа-яА-Я\s\d.,?!«»:;()\-–—*+=]/g;
export const minClientFreezeDays = 7; // week
export const maxClientFreezeDays = 3650; // 10 years
export const minClientFreezeComment = 6;
export const maxClientFreezeComment = 255;

export type PaymentTypes = 'yookassa' | 'robokassa' | 'manual' | 'present';
