import { createSlice } from '@reduxjs/toolkit';

import { ReferralProgrammeState } from 'src/@types/referralProgramme';
import { INITIAL_PER_PAGE } from 'src/config';

const initialState: ReferralProgrammeState = {
  isLoading: false,
  referralProgrammeList: {},
  referralProgrammeListParams: { per_page: INITIAL_PER_PAGE },
  referralProgramme: null,
};

const slice = createSlice({
  name: 'referralProgramme',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getReferralProgrammesList(state, action) {
      state.isLoading = false;
      state.referralProgrammeList = action.payload;
    },

    getReferralProgramme(state, action) {
      state.isLoading = false;
      state.referralProgramme = action.payload;
    },

    setReferralProgrammeListParams(state, action) {
      state.referralProgrammeListParams = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  resetError,
  getReferralProgrammesList,
  setReferralProgrammeListParams,
  getReferralProgramme,
} = slice.actions;
