import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiServices } from 'src/api';
import { AppDispatch, RootState } from 'src/redux/store';

export interface ThunkExtraArg {
  api: typeof ApiServices;
}

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
  extra: ThunkExtraArg;
}>();
