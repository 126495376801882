// ----------------------------------------------------------------------
import { CommonState, ListParams, Pagination } from './common';

export type Constant = {
  id: number;
  key: string;
  type: 'list' | 'html';
  value: string;
};

export type ConstantList = {
  data?: Constant[];
  meta?: Pagination;
};

export type ConstantListParams = ListParams;

export type ConstantState = CommonState & {
  constantList: ConstantList;
  constantListParams: ConstantListParams;
  constant: Constant | null;
};

export const TYPES = [
  { label: 'html', option: 'html' },
  { label: 'list', option: 'list' },
];

export type ParamsConstantForForm = Omit<Constant, 'id'>;
