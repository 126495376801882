import { HOST_APP } from 'src/config';
import $api from '../axios';
import type { ParamsStoryForForm, Story, StoryList, StoryListParams } from 'src/@types/story';

const getStoryList = (params: StoryListParams) =>
  $api.get<StoryList>(HOST_APP + 'stories', { params });

const getStory = (id: number) => $api.get<{ data: Story }>(HOST_APP + 'stories/' + id);

const editStory = ({ id, ...params }: ParamsStoryForForm) =>
  $api.put(HOST_APP + 'stories/' + id, {
    ...params,
  });

export const StoryServices = { getStory, getStoryList, editStory };
